import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './homepage.style.css'

export default class Homepage extends Component {
    render() {
        return (
            <div>
                <div className="hero-sec">
                    <div className="hero-content">
                        <h3 className="pre-heading">Need a Cargo Service?</h3>
                        <h1>We Got Your Covered</h1>
                        <Link to="/contact" className='btn'>
                            <span>Get a Free Quote</span>
                        </Link>
                    </div>
                </div>
                <section className="features">
                    <div className="container">
                        <div className="section-heading">
                            <h3>We Provide</h3>
                            <h2>Express Cargo Delivery</h2>
                            <p>All Over India</p>
                        </div>
                        <div className="feature-cards row">
                            <div className="col-md-4 card">
                                    <img src="./images/card-1.png" alt="" />
                                    <p>We Deliver, Perishables, Appliances, Machinery & More</p>
                            </div>
                            <div className="col-md-4 card">
                                    <img src="./images/card-2.png" alt="" />
                                    <p>We Deliver enormous payloads of vital medical equipments all over India</p>
                            </div>
                            <div className="col-md-4 card">
                                    <img src="./images/card-3.png" alt="" />
                                    <p>We Ship Live Animals (Pets)</p>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="about-text">
                                    <h3 className="pre-heading">Who we Are</h3> 
                                    <h2 className="heading">About Us</h2>
                                    <p>
                                    Stork Cargo is a product of Deekins Travel Solutions Pvt Ltd . The parent company was founded in 2008 and today stands as a PVT Ltd multi located Ubiquitous organisation providing travel and cargo related services from past 8 years. With  Stork Cargo we aim to build an efficient and robustly operating freight and logistics system. We provide air transportation, warehousing, freight, reverse logistics, cross-border transportation to businesses, companies and individuals . We try to bring flexibility, breadth, efficiency and innovation to our customers’ supply chain and logistics operations. Our operations, infrastructure and technology enable our customers to transact with us and our partners at the lowest costs.
                                    </p>
                                    <Link to="/about" className='btn'>Read More</Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="./images/about.jpeg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='baggage'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="heading">Worried About Excess Baggage?</h2>
                                <h3>We Got You Covered!</h3>
                                <h2 className='big-heading'><span>4200+</span> Pincodes covered nationwide</h2>
                                <p>Price Starting at <span className="big-heading">₹95</span>/kg</p>
                                <p>Price Includes Pick and Drop</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="delivery">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img src="./images/delivery.png" alt="" />
                            </div>
                            <div className="col-md-6">
                                <div className="delivery-text">
                                    <h3 className="pre-heading">We offer</h3>
                                    <h2 className="heading">Hassle Free Delivery</h2>
                                    <p>We make sure our customers do not have to worry about the pick ups and the drops. Our team will contact you as soon as you book your consignments/parcels with us and ensure a fast pickup. Our aim is to make the process easy and customer friendly. From anywhere in India to anywhere in India. To sum up every consignment is :<br/>EASED DESTINED DELIVERED.</p>
                                    <Link to="/contact" className='btn'>Get a Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
