import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class MenuItems extends Component {
    render() {
        return (
            <>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/track">Track</Link></li>
                <li><Link to="/contact">Get a Quote</Link></li>
            </>
        )
    }
}
