import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Sidebar.styles.css'
export default class Sidebar extends Component {
  render() {
    return (
      <div className={this.props.openMenu ? ('sidebar open-sidebar') : ('sidebar')}>
          <div className="close-btn" onClick={this.props.toggleSidebar}><span></span><span></span></div>
          <div className="menus">
              <ul>
              <Link to="/" onClick={this.props.toggleSidebar}><li>Home</li></Link>
              <Link to="/about" onClick={this.props.toggleSidebar}><li>About</li></Link>
              <Link to="/track" onClick={this.props.toggleSidebar}><li>Track</li></Link>
              <Link to="/contact" onClick={this.props.toggleSidebar}> <li>Get a Quote</li></Link>
              </ul>
          </div>
      </div>
    )
  }
}
