import React, { useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import './contact.style.css';

export const ContactUs = () => {
    const[sendMessage, setMessage] = useState("Send Message")
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ibdi3l3', 'template_kl7bxbb', form.current, 'GICI3aDO43Suh7NAI')
      .then((result) => {
          console.log(result.text);
          setMessage("Message sent!")
          form.current.reset();
      }, (error) => {
          console.log(error.text);
          setMessage("Oops! Something Went Wrong")
      });
      
  };

  return (
      <div>
          <div className="header-sec">
              <div className="header-content">
                  <h1>Contact Us</h1>
              </div>
          </div>
            <div className="section contact-sec">
                <div className="container-full">
                    <div className="sec-heading text-center">
                        <h2>Get a Quote Now</h2>
                    </div>
                    <div className="row align-center">
                        <div className="col-md-6 contact-info">
                        <div className="contact-details">
                            <i class="fas fa-phone"></i>
                            <h4>
                            Call Us:
                            </h4>
                                <div className="contact">
                                    <p><a href="tel://919419424242">+91-9419424242</a>
                                    </p>
                                </div>
                            </div>
                            <div className="contact-details">
                            <i class="fab fa-whatsapp"></i>
                            <h4>
                            Text Us on Whatsapp
                            </h4>
                                <div className="contact">
                                    <p><a href="https://wa.me/919419424242">+91 9419424242</a>
                                    </p>
                                </div>
                            </div>
                            <div className="contact-details">
                            <i class="fab fa-instagram"></i>
                            <h4>
                            Follow Us on Instagram
                            </h4>
                                <div className="contact">
                                    <p><a href="https://instagram.com/stork_cargo">@stork_cargo</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form">
                                <h3 className="text-center">Send Us a Message</h3>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="input">
                                        <label>Name *</label>
                                        <input type="text" name="user_name" required placeholder="John Doe"/>
                                    </div>
                                    <div className="input">
                                        <label>Email *</label>
                                        <input type="email" name="user_email" required placeholder="example@example.com"/>
                                    </div>
                                    <div className="input">
                                        <label>Phone Number</label>
                                        <input type="tel" name="user_phone" placeholder="+919999000000"/>
                                    </div>
                                    <div className="input message">
                                        <label>Message *</label>
                                        <textarea name="message" required placeholder="I would like to know the rate for transportation from delhi to kashmir"/>
                                    </div>
                                    <div className="button">
                                        <button type="submit" onClick={() => setMessage("Sending...")}>
                                            {sendMessage}
                                        </button>
                                    </div>
                                    <div className="send-message">
                                        <p></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  );
};