import React, { Component } from 'react'
import axios from 'axios';
import './track.style.css'
export default class Track extends Component {
    constructor() {
        super()
        this.state = {
            formSubmitted: false,
            trackData: {  
            successful: false,          
            TrackingNumber: "",
            Consignor: "",
            Consignee: "",
            From: "",
            Destination: "",
            products: [{quantity: "", lrStatus: "", productDetails: "", weight: "", history: [{ dateTime: "", location: "", status: ""}]}]},
            trackInput: '',
            trackText: 'Track'
        }
    }

handleChange = event =>{
    this.setState({trackInput: event.target.value})
}
handleSubmit = event =>{
    event.preventDefault();

        axios.post("https://storkapi.herokuapp.com/track", {track_id: this.state.trackInput}).then(res => {
            console.log(res.data)
            this.setState({trackData: res.data, formSubmitted: true, trackText: 'Track'})
        });
}
invalidNumber = () =>{
    if(this.state.formSubmitted){
        if(this.state.trackData.successful === false){
            return  <span>Invalid Tracking Id</span>
        }
    }
}
    render() {
        const trackingData = this.state.trackData;

        return (
            
            <div>
                <div className="track-form">
                    <form onSubmit={this.handleSubmit}>
                    <label htmlFor="">Tracking Number</label>
                    <div className="form-input">
                        <input type="text" name="trackInput" onChange={this.handleChange} placeholder="Enter Your Tracking Number"/>
                        <button type="submit" onClick = { () => this.setState({trackText: "Tracking..."})}>{this.state.trackText}</button>
                    </div>
                    <p>{this.invalidNumber()}</p>
                </form>
                </div>

                
            { this.state.formSubmitted && trackingData.successful ? ( 
                <div>
                    <div className="track-info section">
                        <div className="container">
                            <div className="track-status">
                            <h2>Tracking Number: <strong>{trackingData.TrackingNumber}</strong></h2> 
                                <h3>{trackingData.products[0].lrStatus} </h3>
                            </div>
                            <div className="track-bar">
                                
                            <div className={`track-his ${trackingData.products[0].history.length >= 1 ? 'active' : ''}`}>Booking Confirmed</div>
                            <div className={`track-his ${trackingData.products[0].history.length >= 2 ? 'active' : ''}`}>Arrived at Destination</div>
                            <div className={`track-his ${trackingData.products[0].history.length >= 3 ? 'active' : ''}`}>Scheduled for Delivery</div>
                            <div className={`track-his ${trackingData.products[0].history.length >= 4 ? 'active' : ''}`}>Delivered</div>
                            </div>
                        </div>
                    </div>
                    <div className="track-details section">
                        <div className="container">
                            <div className="row destination">
                                <div className="text-center col-sm-6">
                                    <h2>{trackingData.From}</h2>
                                </div>
                                <div className="text-center col-sm-6">
                                    <h2>{trackingData.Destination}</h2>
                                </div>
                            </div>
                            <div className="row consignor">
                                <div className="col-md-6">
                                    <div className="product-info">
                                        <p><strong>Consignor:</strong> {trackingData.Consignor}</p>
                                        <p><strong>Consignee:</strong> {trackingData.Consignee}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product-info">
                                        <p><strong>Quantity: </strong> 
                                            {trackingData.products.reduce((acc, quan) => acc + quan.quantity, 0)} Pcs.
                                        </p>
                                        <p><strong>Weight: </strong> 
                                            {trackingData.products.reduce((acc, weight) => parseFloat(acc) + parseFloat(weight.weight), 0)} KGs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        <section>
                        <h2 style={{fontWeight: '500', marginBottom: '40px'}}>History</h2>
                            <div className="row">
                                        {trackingData.products[0].history.map((historyData) => 
                                            <div className="col-md-6">
                                                <div className="product-info">
                                                <p><strong>Date and Time: </strong>{historyData.dateTime}</p>
                                                <p><strong>Location: </strong>{historyData.location}</p>
                                                <p><strong>Status: </strong>{historyData.status}</p>
                                                </div>
                                            </div>
                                        ).reverse()
                                        }
                            </div>
                        </section>
                        </div>
                    </div> 
                </div>
             )  
             : ''  }

            </div>
            
        )
    }
}

