import React, {useState} from 'react';
import About from "../About/About";
import Homepage from '../Homepage/Homepage';
import Track from '../Track/Track';
import { ContactUs } from '../contact/contact';
import logo from '../../assets/logo2.png';
import "./header.styles.css";
import {Switch, Route, Link} from "react-router-dom";
import Sidebar from '../Sidebar/Sidebar';
import MenuItems from '../MenuItems/MenuItems';
import Footer from '../footer/Footer';



export default function Header() {
    const [menu, setMenu] = useState(false);


    function toggleMenu(){
        setMenu(prevState => !prevState)
        console.log(menu)
    }
    return (
        <div>
            <div className="header">
                <div className="logo col-md-6">
                    <Link to="/"><img src={logo} alt="Stork Cargo Logo" /></Link>
                </div>
                <div className="menu col-md-6">

                    <ul>
                        <MenuItems/>
                    </ul>
                    <div className="burger-menu" onClick={toggleMenu}>
                        <span></span><span></span><span></span>
                    </div>
                </div>
                <Sidebar openMenu={menu} toggleSidebar={toggleMenu}/>
            </div>
            <div className="page">
                <Switch>
                    <Route exact path="/">
                        <Homepage />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/track">
                        <Track />
                    </Route>
                    <Route path="/contact">
                        <ContactUs />
                    </Route>
                </Switch>
                <Footer/>
            </div>
        </div>
    )
}
