import React from 'react'
import { Link } from 'react-router-dom'
import './About.styles.css'
export default function About() {
  return (
    <>
      <div className="header-sec about">
        <div className="header-content">
          <h1>About Us</h1>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row justify-center">
            <div className="col-md-8 ">
            <h3 className="pre-heading">Who we Are</h3> 
            <h2 className="heading">About Us</h2>
            <p>Stork Cargo is a product of Deekins Travel Solutions Pvt Ltd . The parent company was founded in 2008 and today stands as a PVT Ltd multi located Ubiquitous organisation providing travel and cargo related services from past 8 years. With  Stork Cargo we aim to build an efficient and robustly operating freight and logistics system. We provide air transportation, warehousing, freight, reverse logistics, cross-border transportation to businesses, companies and individuals . We try to bring flexibility, breadth, efficiency and innovation to our customers’ supply chain and logistics operations. Our operations, infrastructure and technology enable our customers to transact with us and our partners at the lowest costs.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='quote-section'>
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-md-8">
                <h4 className='pre-heading' style={{color: '#fff'}}>Deliver Your Products</h4>
                <h2 className='heading' style={{marginBottom: '60px'}}>Book Your Slot Now</h2>
                <Link to="/contant" className='btn'>Book Now</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
