import React, { Component } from 'react'
import './Footer.styles.css'
export default class Footer extends Component {
  render() {
    return (
        <>
              <footer>
          <div className="container">
              <div className="row align-center text-center justify-center">
                  <div className="col-md-8">
                      <img src="./images/logo-w.png" alt='Logistics in Kashmir & Leh' className='footer-logo'/>
                      <h6>Stork Cargo</h6>
                      <p>A unit of Deekins Travel Solutions Pvt Ltd</p>
                    <div className="talk">
                    <h6>Talk To Us</h6>
                    <p>Call Us: <a href="tel:919419424242">+91 9419 424242</a></p>
                    <p>Write To Us: <a href="mailto:contact@storkcargo.in">contact@storkcargo.in</a></p>
                    </div>
                  </div>
              </div>
          </div>
      </footer>
      <div className="credits">
          <div className="container-full">
              <div className="row">
              <div className="col-md-6 m-text-center">
                        <p>Copyright Stork Cargo | {new Date().getFullYear()}</p>
                        </div>
                        <div className="col-md-6 text-end m-text-center">
                        <p>Developed By <a href="https://wa.me/917006519288" target="_blank" rel="noreferrer">Aamir Beigh</a></p>
                        </div>
                  <div className="col-md-6"></div>
              </div>
          </div>
      </div>
        </>
    )
  }
}
